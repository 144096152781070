.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  text-align: left;
  background: rgba(255,255,255, .1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid rgba(255,255,255, .25);;
  border-left: 1px solid rgba(255,255,255, .25);;
  border-right: 1px solid rgba(255,255,255, .25);;
  padding: 20px;
  font-weight: bold;
  align-items: center;
}

.container * {
  align-items: center;
}

.profileRow {
  display: inline-block;
  line-height: 50px;
  vertical-align: middle;
  margin-left: 14px;
  font-size: 16px;
  font-weight: bold;
}

.userHandle {
  display: inline-block;
  margin-left: 24px;
}

.center {
  text-align: center;
  justify-content: center;
}

.formContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 100%;
  background: #222;
  height: 400px;
  border-radius: 4px;
  border: 1px solid #777;
  padding: 8px;
}

.align-right {
  float: right;
}

.shared {
  &-normal-font {
    font-weight: normal;
    &-big {
      @extend .shared-normal-font;
      font-size: 24px;
    }
  }

  &-bold-font {
    font-weight: bold;

    &-big {
      @extend .shared-bold-font;
      font-size: 24px;
    }
  }
}
