.mainCard {
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.2rem;
  padding: 1rem;
  color: #757575;
  border-radius: 0.3125rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.page {
  display: block;
  padding: 10px;
}

.pageMessageError {
  color: red;
  font-size: 1.2rem;
  font-weight: bold;
}

.pageMessageContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pageMessageWarning {
  color: darkorange;
  font-size: 1.2rem;
  font-weight: bold;
}

.pageTitle {
  color: darkgrey;
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 2.4rem;
  margin-top: 0.2rem;
}
