.take-space {
  height: 50px;
  width: 100%;
}

.top-right {
  position: absolute;
  width: 50px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  top: 10px;
  left: calc(100% - 60px);
}
